import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { URLS } from '../../config/api.config';
import { Storage } from '@ionic/storage';
import { CustomEncryption } from '../../config/custom-encrypt';
import { environment, superStarObj } from 'src/environments/environment';



@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;
    // secret key to encrypt the login credentails
//   private secretKey: string = SECRET_KEY;

    // observable user details
    // public currentUserSubject: BehaviorSubject<any>;

    // onserveable logged in status
    public loggedInStatus;

    // logged in user tpe
    // public currentUser: Observable<any>;

    // login api url
    private RMloginUrl = URLS.request3;

    // logout api url
    private logoutUrl = URLS.swarajLogout;
    private cookieKEY = environment['cookieKEY'];
    constructor(
      private http: HttpClient,
      private cipherText : CustomEncryption,
      private storage: Storage
      ) {
        // this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUser')));
        this.loggedInStatus = new BehaviorSubject<boolean>(this.hasToken());
        // this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(this.storage.get('currentUser')));
        // this.currentUser = this.currentUserSubject.asObservable();
    }

  //   public get currentUserValue(): any {
  //     return this.currentUserSubject.value;
  // }


  /**
   * if we have token the user is loggedIn
   * @returns {boolean}
   */
  private hasToken() : boolean {
    return !!this.storage.get('token');
  }


  /**
   * if we have token the user is loggedIn
   * @returns {boolean}
   */
  private hasSubscription() : boolean {
    return !!this.storage.get('susbcription');
  }



/**
*
* @returns {Observable<T>}
*/
    public isLoggedIn() {
      return this.loggedInStatus.asObservable();
    }

/**
 * Login the user then tell all the subscribers about the new status
 */
  public login(apiURL, loginData: any) {
    // const cipherData = crypto.AES.encrypt(JSON.stringify(loginData), this.secretKey).toString();
    // const cipherData = this.cipherText.aesEncrypt(loginData);
    // const cipherPasswrd = crypto.AES.encrypt(password, this.secretKey).toString();
    return  this.http.post<any>(apiURL, loginData,{headers: new HttpHeaders(this.cookieKEY)})
      .pipe(map(user => {
        // login successful if there's a jwt token in the response
        if ((user && user['Body'])) {
          if (+user['Body']['Status'] === 0) {
            this.storage.set('token', true);
            this.loggedInStatus.next(true);
              // store user details and jwt token in local storage to keep user logged in between page refreshes
            //  localStorage.setItem('currentUser', JSON.stringify(user));
            //  this.currentUserSubject.next(user);
            return user;
          } 
        } else if (user && user['body']) {
            if (+user['body']['status'] === 0) {
              this.storage.set('token', true);
              this.loggedInStatus.next(true);
              return user;
            } 
          } else {
          return user;
        }
      }));
  }

    public logout(obj, tokenValue) {
      this.loggedInStatus.next(false);
      const newToken = {
        'token': tokenValue
      }
      return this.http.post<any>(`${this.logoutUrl.url}`, obj, { headers: new HttpHeaders(Object.assign(newToken,this.cookieKEY))});
         // remove user from local storage to log user out
        //  localStorage.removeItem('currentUser');
        //  this.currentUserSubject.next(null);
    }
}
