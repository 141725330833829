import { Injectable } from "@angular/core";
import { Subject } from 'rxjs';

@Injectable()
export class CommonService {

    private field: string;
    public eventObservable = new Subject<any>();
    public event: any = {};
    public formattedDate: any;

  
    constructor() { }

    setData(val) {
        this.field = val;
    }

    getData() {
        return this.field;
    }

    clearEvent() {
        this.eventObservable.next();
    }

    /**	    
     * @param key : Property name sets user data with a named key as passed key in argument
     * @param data : Setter sets user data on passed key in argument
     */

    public setEvent(event: string, data: any) {	
        /* console.log("setEvent"); 
        console.log('data in service',data);     */
        this.event = {'event': event, 'data': data};	
        this.eventObservable.next(this.event);	
    }

    getDate(val) {
        let sliceddate = val.slice(6, 19);
        let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        let utcSeconds = sliceddate / 1000;
        let date1 = new Date(0); // The 0 there is the key, which sets the date to the epoch
        date1.setUTCSeconds(utcSeconds);
        let date = date1.getDate();
        let month = months[date1.getMonth()];
        let year = date1.getFullYear();
    

		return this.formattedDate = date + ' ' + month + ' ' + year;
    }

    formatDate(date) {
      var dd = date.getDate();
      var mm = date.getMonth() + 1;
      var yyyy = date.getFullYear();
      if (dd < 10) { dd = '0' + dd }
      if (mm < 10) { mm = '0' + mm }
      date = yyyy.toString() + mm + dd;
      return date
    }

    	// last one month dates
    lastMonthDate(monthValue) {
      var d = new Date();
      if (monthValue == "previous") {
        d.setDate(d.getDate() - 30);
        return this.formatDate(d)
      }
      else if(monthValue == "current"){
        d.setDate(d.getDate() );
        return this.formatDate(d)
      }
      
    }

    getToday(value) {
      var dd = value.getDate();
      var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
          var yyyy = value.getFullYear();
          let mm = months[value.getMonth()];
          if(dd<10) 
          {
              dd='0'+dd;
          }
        return  value = dd +'-'+mm+'-'+yyyy;
    }
    
    displayDecimalDigits(value) {
        if(value == 0 || value == Math.round(value)){
          return value
        }
        else{
         return value.toFixed(2);
        }
       
     }

     formatNumberComma(value){
      var parts = value.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return parts.join(".");
        // var x = value.toString();
        // var afterPoint = '';
        // if(x.indexOf('.') > 0)
        //    afterPoint = x.substring(x.indexOf('.'),x.length);
        // x = Math.floor(x);
        // x=x.toString();
        // var lastThree = x.substring(x.length-3);
        // var otherNumbers = x.substring(0,x.length-3);
        // if(otherNumbers != '')
        //     lastThree = ',' + lastThree;
        // var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree + afterPoint
        // return res;
     }

    numberFormatWithOnly_K(value){
      var val;
      val = Math.abs(value)
      val = this.formatNumberComma(this.displayDecimalDigits(val / 1000));
     return val;
    }

     numberFormatWithCommaUnit(value){
        var val, signValue;
        signValue = value
        val = Math.abs(value)
        if (val >= 10000000) {
          val = this.formatNumberComma(this.displayDecimalDigits(val / 10000000)) + ' Cr';
          
        } else if (val >= 100000) {
          val = this.formatNumberComma(this.displayDecimalDigits(val / 100000)) + ' L' ;
        }
        else if(val >= 1000){
         val = this.formatNumberComma(this.displayDecimalDigits(val / 1000)) + ' K';
        }	
        else if(val < 100 )	{
          val = this.displayDecimalDigits(val);
        }	
        if(signValue < 0){
          return '-'+val;
        }
        else{
          return val;
        }
      }
  

     numberFormatWithUnit(value) {
      var val, signValue;
        signValue = value
        val = Math.abs(value)
        if (val >= 10000000) {
          val =  this.displayDecimalDigits(val / 10000000) + 'Cr';
          
        } else if (val >= 100000) {
          val = this.displayDecimalDigits(val / 100000) + 'L';
        }
        else if(val >= 1000){
         val = this.displayDecimalDigits(val / 1000) + 'K';
        }	
        else if(val < 100 )	{
          val = this.displayDecimalDigits(val);
        }	
          if(signValue < 0){
          return '-'+val;
        }
        else{
          return val;
        }
      }
    
}