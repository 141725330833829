// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	apiUrl: 'http://swarajuat.iifl.in/',
	apiSecureUrl: 'https://swarajuat.iifl.in/',
	consolidateURL: 'https://swaraj.indiainfoline.com/',
	backOfficeURL: 'https://boservicesuat.iifl.in/',
	baseUrl: 'https://dataservice.iifl.in/aaa/uat/',
	UATbaseUrl: 'https://dataservice.iifl.in/aaa/uat/',
	superStar: 'https://dataservice.iifl.in/aaa/uat/',
	forgotPasswordURL: 'http://boservicesuat.iifl.in/OTPGeneration/frmOTPGeneration.aspx',
	indiaInfoUrl: 'https://uatpapi.indiainfoline.com/',
	newsinfoUrl: 'https://api.indiainfoline.com/',
	cookieURL: 'https://dataservice.iifl.in/',
	cookieKEY: {
		'Ocp-Apim-Subscription-Key': '75e5ccdf4ba84de583cfe81f5b89158a'
	  },
	bodyParams: {
		head: {
			appName: "IIFLMarkets",
			appVer: "1.0.20.0",
			key: "dL8mG4UwCfVYjzPWu8Rp",
			osName: "Android",
			requestCode: "IIFLMarRQGetNewMarketWatchV5"
		},
	body: {
			// Clientcode: localStorage.getItem('userID'),
			// Clientcode: 'ckv000rm',
			//MWName: "NIFTY50",
			// ClientLoginType: 0
			}
	},
	optionalHeaders: {
		UserId: 'Hek68PU5r76',
        Password: 'D86utK8Mn7'
	},
	headersParams: {
		UserId: 'Hek68PU5r76',
		Password: 'D86utK8Mn7',
		'Ocp-Apim-Subscription-Key': '75e5ccdf4ba84de583cfe81f5b89158a'
	}
};

export const ChartLink = {
	Chart : {
		basic: 'https://images.indiainfoline.com/mailers/ChartIq-Swaraj/uat_chartIQ7.5/basic.html?type=future-curcom&period=1&range=d&exchange=',
		advanced:'https://images.indiainfoline.com/mailers/ChartIq-Swaraj/uat_chartIQ7.5/advance.html?type=future-curcom&period=1&range=d&exchange='
	},

}

/* FOR INVEST SCREEN  */
export const investObj = {
	fdURL : {
		shriram: 'http://cos.stfc.me/cos/affiliate/Cos_SchemeDetails.aspx',
		icici: 'https://www.icicihfc.com/fixed-deposit/fd-online-application-form/broker-details.html',
		bajaj: 'https://cont-sites.bajajfinserv.in/fixed-deposit-application-form'
	},
	bondURL: {
		irfc: 'https://kosmic.kfintech.com/IRFC/Brokers/default.aspx?k=A354A4',
		recBond: 'https://kosmic.kfintech.com/REC/brokers/default.aspx?k=C9AED7',
		pfcBond: 'https://kosmic.kfintech.com/PFC/Brokers/default.aspx?k=B6ACC6'
	},
	loans: {
		encryptKey: 'IIFLLead349898ii',
		ivs: 'IIFLLead349898ii',
		businessLoan: 'https://uat.iifl.com/securities/business-partners/aaa/business-loan/',
		personalLoan: 'https://uat.iifl.com/securities/business-partners/aaa/personal-loan/',
		homeLoan: 'https://uat.iifl.com/securities/business-partners/aaa/home-loan/index.php'
	},
	addUser: {
		getTokenURL: 'https://dataservice.iifl.in/aaa/uat/GetClientAuthenticationToken',
		vid: 'HK20SR5AC30',
		addClientURL: 'http://eauataz.iifl.in/Dashboard/RMDashboard',
		addSubbrokerURL: 'http://subbrokeruataz.iifl.in/Dashboard/RMDashboard',
		addNRI: 'https://nri-eaccount.indiainfoline.com/'
	},
	fp360 : {
		url: 'https://azbharat.indiainfoline.com/MoneywarePortal/ClientPortal/UI/SSOFPLogin',
	},
	smallCase : {
		// url: 'https://smallcases-dev.indiainfoline.com/',
		apiURL: 'https://portfolio.indiainfoline.com/smallcase/Home/GetAuthorize',
		redirectURL: 'https://smallcases-dev.indiainfoline.com/'
	},
	offlineIPO : {
		url: 'http://internaladminuat.iifl.in/OfflineIPO/OfflineIPOEntry.aspx?',
	},
	onlineIPO : {
		url: 'https://myaccountuat.iifl.in/myaccount/IPO.aspx',
		domain: '.iifl.in'
	},
	financialHealth : {
		url: 'https://swarajuat.iifl.in/CIBILWeb?business=securities&utm_source=iiflmarketsapp',
		// appFinancialKey: 'Ap!Cib!lWEb@pR0d7294',
		// encryptKey: 'Nq6tK2Jy^2Md#73v'
		appFinancialKey: 'Ap!C!bilWeB@Uat6379',
		encryptKey: 'Mt5zD9Hh$6mA@87t'
	},
	mutualFund: {
		url: 'http://mfapps.iifl.in/MFOnline/Login/MFDeeplink'
	},
	insurance: {
		url: 'https://iifl.riskcovry.com/',
		encryptKey: 'c5e00c8bed59e6b7eaaf1593fbc28793',
		ivs: 'dea11e5bc57f9c9d',
		partnerCode: 'IFAAA'
	},
	goalCalculator:{
		url: 'http://mfapps.iifl.in/MFOnline/Goal',	
	}
}

/* FOR SUPER STAR */
export const superStarObj = {
	userID: 'shF24178l4Ksdj74Android219fff7sjktWue2qr4rrhdkd',
	password: 'thbmdsggjsd7e886ds5821kdhxgkrkbmqdudeklsse6d',
	key: 'eab16d83a9d0f05f9dd9d003a51a816781a67632'
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
