import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
// import { DateService } from './services/date.service';
import { DatePipe } from '@angular/common';
import { JwtInterceptor } from './interceptor/jwt.interceptor';
import { CommonService } from './helpers/common.service';
import { ChartsModule } from 'ng2-charts';

import { IonicStorageModule } from '@ionic/storage';

// import { DaterangepickerModule } from 'angular-2-daterangepicker';


@NgModule({
	declarations: [AppComponent],
	entryComponents: [],
	imports: [BrowserModule, IonicModule.forRoot(),
        AppRoutingModule, HttpClientModule,
		IonicStorageModule.forRoot(),
		// DaterangepickerModule,
		ChartsModule
	],
	providers: [
		StatusBar,
		Title,
		SplashScreen,
		HttpClientModule,
		DatePipe,
		CommonService,
		{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
		{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true }
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
