import { environment } from '../environments/environment';

export const URLS = Object({
	paymentRequest: {
		url: `${environment.baseUrl}AAAPaytmPaymentRequest`,
		key: ''
	},
	generateToken: {
		url: `${environment.baseUrl}GenerateToken`,
		key: ''
	},
	checkSubscription: {
		url: `${environment.baseUrl}AAASubscriptionStatus`,
		key: '446794970AAA1237ab394d176612f8c6'
	},
	addSpan: {
		url: `${environment.baseUrl}AddMarginDetails`,
		key: 'dL8mG4UwCfVYjzPWu8Rp'
	},
	calculateSpan: {
		url: `${environment.baseUrl}SpanMarginCalculation`,
		key: 'dL8mG4UwCfVYjzPWu8Rp'
	},
	marginDetails: {
		url: `${environment.baseUrl}GetMarginDetails`,
		key: 'dL8mG4UwCfVYjzPWu8Rp'
	},
	delMargin: {
		url: `${environment.baseUrl}DeleteMarginDetails`,
		key: 'dL8mG4UwCfVYjzPWu8Rp'
	},
	resetMargin: {
		url: `${environment.baseUrl}ResetMargin`,
		key: 'dL8mG4UwCfVYjzPWu8Rp'
	},
	forgotPass: {
		url: `${environment.baseUrl}ForgotManagerPassword`,
		key: 'dL8mG4UwCfVYjzPWu8Rp'
	},
	swarajLogout: {
		url: `${environment.baseUrl}LogOut`,
		key: 'PMVHB5wpK7LCCYBkl6APNYMqvyNWMZKl'
	},
	backofficeLogout: {
		url: `${environment.baseUrl}EmployeeLogoutV1`,
		key: ''
	},
	swarajCookie: {
		url: `${environment.baseUrl}LoginRequestv2`,
		key: 'PMVHB5wpK7LCCYBkl6APNYMqvyNWMZKl'
	},
	backofficeCookie: {
		url: `${environment.baseUrl}LoginAAAV2`,
		key: ''
	},
	isAgreeCookie: {
		url: `${environment.baseUrl}ISAgreed`,
		key: ''
	},
	holdingCookie: {
		url: `${environment.baseUrl}MyHolding`,
		key: ''
	},
	marketWatchList: {
		url: `${environment.baseUrl}GetNewMarketWatch`,
		key: 'dL8mG4UwCfVYjzPWu8Rp'
	},
	getMWatchList: {
		url: `${environment.baseUrl}GetMWatchList`,
		key: ''
	},
	// need to change in marketfeed v4
	getMFeedList: {
		url: `${environment.baseUrl}MarketFeed`,
		key: 'dL8mG4UwCfVYjzPWu8Rp'
	},
	deleteScrip: {
		url: `${environment.baseUrl}SaveMWV1`,
		key: ''
	},
	setDefault: {
		url: `${environment.baseUrl}ChangeWLAttribute`,
		key: ''
	},
	clientList: {
		url: `${environment.baseUrl}ManagerWiseClientHoldingTrades`,
		key: 'dL8mG4UwCfVYjzPWu8Rp'
	},
	userType: {
		url: `${environment.baseUrl}GetUserTypeRequestV1`,
		key: '446794970AAA1237ab394d176612f8c6'
	},
	request1: {
		url: `${environment.baseUrl}LoginRequestV2`,
		key: 'PMVHB5wpK7LCCYBkl6APNYMqvyNWMZKl'
	},
	request2: {
		url: `${environment.baseUrl}LoginAAAV2`,
		key: '446794970AAA1237ab394d176612f8c6'
	},
	rmProfile: {
		url: `${environment.baseUrl}ProfileDetails`,
		key: '446794970AAA1237ab394d176612f8c6'
	},
	mapRM: {
		url: `${environment.baseUrl}ManagerMappingv1`,
		key: 'PMVHB5wpK7LCCYBkl6APNYMqvyNWMZKl'
	},
	// request3: {
	//   url: `${environment.apiUrl}Fpservices/LoginAuth/ClientLogin`,
	//     key: ''
	// },
	request3: {
		url: `${environment.baseUrl}ClientLogin`,
		key: 'PMVHB5wpK7LCCYBkl6APNYMqvyNWMZKl'
	},
	subBroker: {
		url: `${environment.baseUrl}SubBrokerLoginRequestV4`,
		key: '446794970AAA1237ab394d176612f8c6'
	},
	subBrokerMap: {
		url: `${environment.baseUrl}GetClientSubBrokerMappingRequestV1`,
		key: '446794970AAA1237ab394d176612f8c6'
	},
	gainers: {
		url: `${environment.baseUrl}GainerLooser`,
		key: ''
	},
	changePswd: {
		url: `${environment.baseUrl}ChangePassword`,
		key: 'PMVHB5wpK7LCCYBkl6APNYMqvyNWMZKl'
	},
	isAgree: {
		url: `${environment.baseUrl}ISAgreed`,
		key: '446794970AAA1237ab394d176612f8c6'
	},
	getDates: {
		url: `${environment.baseUrl}FutureScripDetails`,
		key: ''
	},
	getOptionsDate: {
		url: `${environment.baseUrl}OptionsScripExpiry`,
		key: ''
	},
	getOptionsDetails: {
		url: `${environment.baseUrl}OptionScripDetails`,
		key: ''
	},
	holding: {
		url: `${environment.baseUrl}HoldingAAA`,
		key: ''
	},
	tradeFor: {
		url: `${environment.apiSecureUrl}TTWebAPIServices/TradingRelatedAPI/TradeBookV1`,
		key: ''
	},
	superstar: {
		url: `${environment.baseUrl}superstarsStock/`,
		key: 'eab16d83a9d0f05f9dd9d003a51a816781a67632'
	},
	superStarIndex: {
		url: `${environment.baseUrl}getSuperStarIndex/`,
		key: 'eab16d83a9d0f05f9dd9d003a51a816781a67632'
	},
	superStarShares: {
		url: `${environment.baseUrl}custom/?superStarName=`,
		key: '2cccc59bdab77bace6189d001f96487e'
	},
	superStarportfolio: {
		url: `${environment.baseUrl}`,
		key: 'eab16d83a9d0f05f9dd9d003a51a816781a67632'
	},
	superStarHistory: {
		url: `${environment.baseUrl}`,
		key: 'eab16d83a9d0f05f9dd9d003a51a816781a67632'
	},
	cashfilter: {
		url: `${environment.baseUrl}CashFilter`,
		key: ''
	},
	cashtabdetails: {
		url: `${environment.baseUrl}CompanyDetailPage`,
		key: ''
	},
	marketdepth: {
		url: `${environment.baseUrl}MarketDepth`,
		key: ''
	},
	futureOptDetails: {
		url: `${environment.baseUrl}FutOptIndvidualStockDetail-version2/`,
		key: ''
	},
	updateFutureOptDetails: {
		url: `${environment.baseUrl}CompanyDetailPageV1`,
		key: 'PMVHB5wpK7LCCYBkl6APNYMqvyNWMZKl'
	},
	cashscripcode: {
		url: `${environment.baseUrl}GetCashScripCode`,
		key: 'dL8mG4UwCfVYjzPWu8Rp'
	},
	getoptionforsymbol: {
		url: `${environment.baseUrl}GetOptionsForSymbol`,
		key: ''
	},
	contractinfo: {
		url: `${environment.baseUrl}ScripDetailsFoFOOrderV3`,
		key: 'PMVHB5wpK7LCCYBkl6APNYMqvyNWMZKl'
	},
	newstabList: {
		url: `${environment.newsinfoUrl}core/home-page/company-news1/page/1/perpage/15/symbol/`,
		key: ''
	},
	newstabDetails: {
		url: `${environment.newsinfoUrl}core/home-page/article-content/articleid/`,
		key: ''
	},

	weekhighlow: {
		url: `${environment.baseUrl}52weekhighlow-version2/`,
		key: ''
	},
	bulkblockdeal: {
		url: `${environment.baseUrl}bulkdeals-version2/`,
		key: ''
	},
	voltopper: {
		url: `${environment.baseUrl}TopTraded`,
		key: 'dL8mG4UwCfVYjzPWu8Rp'
	},
	// searchURL
	equityCash: {
		url: `${environment.baseUrl}SearchScrip`,
		key: ''
	},
	equityFutureOpt: {
		url: `${environment.baseUrl}SearchFOScrip`,
		key: ''
	},
	marketfeedsearch: {
		url: `${environment.baseUrl}MarketFeedV3`,
		key: ''
	},
	commodityMarketfeed: {
		url: `${environment.baseUrl}MarketFeedV1`,
		key: ''
	},
	currencyFutureOpt: {
		url: `${environment.baseUrl}FOCurrencyScripList`,
		key: ''
	},
	commodityFuture: {
		url: `${environment.baseUrl}CommodityScripListIPAD`,
		key: ''
	},
	// Details for future
	searchFutureDetails: {
		url: `${environment.baseUrl}FutureScripDetails`,
		key: ''
	},
	searchFutCommoDetails: {
		url: `${environment.baseUrl}CommScripDetails`,
		key: ''
	},
	// Details for future
	searchOptionExpiry: {
		url: `${environment.baseUrl}OptionsScripExpiry`,
		key: ''
	},
	searchOptionScripDetail: {
		url: `${environment.baseUrl}OptionScripDetails`,
		key: ''
	},
	// Client & Trades API
	clientHoldingMargin: {
		url: `${environment.baseUrl}GetClientHoldingAndMarginDetails`,
		key: 'dL8mG4UwCfVYjzPWu8Rp'
	},
	orderBook: {
		url: `${environment.baseUrl}OrderBookNew`,
		key: 'PMVHB5wpK7LCCYBkl6APNYMqvyNWMZKl'
	},
	tradeBook: {
		url: `${environment.baseUrl}TradeBookV1`,
		key: 'PMVHB5wpK7LCCYBkl6APNYMqvyNWMZKl'
	},
	marginV2: {
		url: `${environment.baseUrl}MarginV2`,
		key: 'PMVHB5wpK7LCCYBkl6APNYMqvyNWMZKl'
	},
	clientLedger1: {
		url: `${environment.baseUrl}ClientLedgerV1`,
		key: 'PMVHB5wpK7LCCYBkl6APNYMqvyNWMZKl'
	},
	clientHolding: {
		url: `${environment.baseUrl}MyHolding`,
		key: 'PMVHB5wpK7LCCYBkl6APNYMqvyNWMZKl'
	},
	clientNetPositioneq: {
		url: `${environment.baseUrl}GetEquityTradeData`,
		key: 'PMVHB5wpK7LCCYBkl6APNYMqvyNWMZKl'
	},
	clientNetPositioncomm: {
		url: `${environment.baseUrl}GetCommodityTradedata`,
		key: 'PMVHB5wpK7LCCYBkl6APNYMqvyNWMZKl'
	},
	clientHoldingPL: {
		url: `${environment.baseUrl}HoldingPLSummaryV2`,
		key: 'PMVHB5wpK7LCCYBkl6APNYMqvyNWMZKl'
	},
	
	// Client & Trades for Consolidate
	consolidateHolding: {
		url: `${environment.baseUrl}HoldingAAA`,
		key: 'PMVHB5wpK7LCCYBkl6APNYMqvyNWMZKl'
	},
	consolidateCommodityHolding: {
		url: `${environment.baseUrl}HoldingCommAAA`,
		key: 'PMVHB5wpK7LCCYBkl6APNYMqvyNWMZKl'
	},
	consolidateTradeBook: {
		url: `${environment.baseUrl}TradeBookForAAA`,
		key: 'PMVHB5wpK7LCCYBkl6APNYMqvyNWMZKl'
	},
	consolidateOrderBook: {
		url: `${environment.baseUrl}OrderBookForAAA`,
		key: 'dL8mG4UwCfVYjzPWu8Rp'
	},
	dashboarddetail: {
		url: `${environment.baseUrl}DashboardDetail`,
		key: '446794970AAA1237ab394d176612f8c6'
	},
	aumdetail: {
		url: `${environment.baseUrl}AUMdetails`,
		key: '446794970AAA1237ab394d176612f8c6'
	},
	aumEquity: {
		url: `${environment.baseUrl}EquityAUMClientwise`,
		key: '446794970AAA1237ab394d176612f8c6'
	},
	aumMutualFund: {
		url: `${environment.baseUrl}MFAUMClientwise`,
		key: '446794970AAA1237ab394d176612f8c6'
	},
	aumFDBond: {
		url: `${environment.baseUrl}GetFDBondNCDAUMClientwiseDetails`,
		key: '446794970AAA1237ab394d176612f8c6'
	},
	aumPMS: {
		url: `${environment.baseUrl}GetPMSAIFAUMClientwiseDetails`,
		key: '446794970AAA1237ab394d176612f8c6'
	},
	aumMLDS: {
		url: `${environment.baseUrl}GetMLDPTCAUMClientwiseDetails`,
		key: '446794970AAA1237ab394d176612f8c6'
	},
	sipBook: {
		url: `${environment.baseUrl}SIPDetails`,
		key: '446794970AAA1237ab394d176612f8c6'
	},
	sipLive: {
		url: `${environment.baseUrl}SIPClientwiseDetails`,
		key: '446794970AAA1237ab394d176612f8c6'
	},
	clientDetail: {
		url: `${environment.baseUrl}TotalClientsDetails`,
		key: '446794970AAA1237ab394d176612f8c6'
	},
	clientDetailList: {
		url: `${environment.baseUrl}ViewAllClients`,
		key: '446794970AAA1237ab394d176612f8c6'
	},
	brokerageDetails: {
		url: `${environment.baseUrl}BrokerageDetails`,
		key: '446794970AAA1237ab394d176612f8c6'
	},
	brokerageDetailsList: {
		url: `${environment.baseUrl}ClientWiseBrokerage`,
		key: '446794970AAA1237ab394d176612f8c6'
	},
	hierarchyList: {
		url: `${environment.baseUrl}GetRMHierarchyV1`,
		key: '446794970AAA1237ab394d176612f8c6'
	},
})

// export const SECRET_KEY: string = '$2a$10$e.oPc.dyrwRoQCpDvO9Rhe';
