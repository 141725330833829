import { Injectable } from '@angular/core';
import * as aesjs from 'aes-js';

@Injectable({
	providedIn: 'root'
  })
export class CustomEncryption {
    
    /**
     * Logic to encrypt using AES/CBC/PKCS7 Encryption method
     * @data data to encrypt
     * @keyArray byte aaray key
     * @ivs intialisation vector byte array
     */
    public aesEncrypt(data, keyArray?, ivs?, type?) {
		let key = [];
		let iv = [];

        if (keyArray) {
			if (typeof(keyArray) === 'string') {
				const returnValue = aesjs.utils.utf8.toBytes(keyArray);
				key = returnValue;
			} else {
				key = keyArray
			}
        } else {
            key = [132, 42, 53, 124, 75, 56, 87, 38, 9, 10, 161, 132, 183, 91, 105, 16, 117, 218, 149, 230, 221, 212, 235, 64]; // default
        }

        if (ivs) {
			if (typeof(ivs) === 'string') {
				const returnValue = aesjs.utils.utf8.toBytes(ivs);
				iv = returnValue;
			} else {
				iv = ivs;
			}
        } else {
            iv = [83, 71, 26, 58, 54, 35, 22, 11, 83, 71, 26, 58, 54, 35, 22, 11]; // default
        }

		if (typeof data === 'object') {
			var text = JSON.stringify(data);
			var textBytes = aesjs.utils.utf8.toBytes(text);
		} else {
			var text2 = data;
			var textBytes = aesjs.utils.utf8.toBytes(text2);
		}

		if (type === 'ecb') {
			var aesCbc = new aesjs.ModeOfOperation.ecb(key, null);
		} else {
			var aesCbc = new aesjs.ModeOfOperation.cbc(key, iv);
		}
		// var aesCbc = new aesjs.ModeOfOperation.cbc(key, iv);
		// var aesCbc = new aesjs.ModeOfOperation.ecb(key, null);
		var encryptedBytes = aesCbc.encrypt(aesjs.padding.pkcs7.pad(textBytes));

		// To print or store the binary data, you may convert it to hex
		var encryptedHex = aesjs.utils.hex.fromBytes(encryptedBytes);
		var hexArray = encryptedHex
			.replace(/\r|\n/g, "")
			.replace(/([\da-fA-F]{2}) ?/g, "0x$1 ")
			.replace(/ +$/, "")
			.split(" ");
		var byteString = String.fromCharCode.apply(null, hexArray);
		var base64string = window.btoa(byteString);
		
		// The cipher-block chaining mode of operation maintains internal
		// state, so to decrypt a new instance must be instantiated.
		// var aesCbc = new aesjs.ModeOfOperation.cbc(key, iv);
		// var decryptedBytes = aesCbc.decrypt(encryptedBytes);

		// // Convert our bytes back into text
		// var decryptedText = aesjs.utils.utf8.fromBytes(decryptedBytes);
		// console.log(decryptedText.toString());
		return base64string;
	}

	public toUTF8Array(str) {
		var utf8 = [];
		for (var i=0; i < str.length; i++) {
			var charcode = str.charCodeAt(i);
			if (charcode < 0x80) utf8.push(charcode);
			else if (charcode < 0x800) {
				utf8.push(0xc0 | (charcode >> 6), 
						  0x80 | (charcode & 0x3f));
			}
			else if (charcode < 0xd800 || charcode >= 0xe000) {
				utf8.push(0xe0 | (charcode >> 12), 
						  0x80 | ((charcode>>6) & 0x3f), 
						  0x80 | (charcode & 0x3f));
			}
			// surrogate pair
			else {
				i++;
				// UTF-16 encodes 0x10000-0x10FFFF by
				// subtracting 0x10000 and splitting the
				// 20 bits of 0x0-0xFFFFF into two halves
				charcode = 0x10000 + (((charcode & 0x3ff)<<10)
						  | (str.charCodeAt(i) & 0x3ff));
				utf8.push(0xf0 | (charcode >>18), 
						  0x80 | ((charcode>>12) & 0x3f), 
						  0x80 | ((charcode>>6) & 0x3f), 
						  0x80 | (charcode & 0x3f));
			}
		}
		return utf8;
	}

	public encryptionforIPO(plain: string) : String {
        let Encode64 = "";
        const Map64 = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";
        let t: number;
		let i: number;
		const textLength = Math.floor((plain.length) / 3) * 3 ;

        for (i = 0; i < textLength; i = i + 3) {		
			t = plain.charCodeAt(i) * 65536 + ( plain.charCodeAt(i + 1)) * 256 + plain.charCodeAt(i + 2);
			Encode64 = Encode64 + Map64.charAt((t / 262144)) + Map64.charAt((t & 258048) / 4096) + Map64.charAt((t & 4032) / 64) + Map64.charAt((t & 63));
        }
        switch (plain.length % 3) {
            case 1:
                t = plain.charCodeAt(i) * 65536;
                Encode64 = Encode64 + Map64.charAt((t / 262144)) + Map64.charAt((t & 258048) / 4096);
                break;
            case 2:
                t = plain.charCodeAt(i) * 65536 + (plain.charCodeAt(i + 1)) * 256;
                Encode64 = Encode64 + Map64.charAt((t / 262144)) + Map64.charAt((t & 258048) / 4096) + Map64.charAt((t & 4032) / 64);
                break;
        }
        return Encode64;
	}
}