import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'sign-in',
    loadChildren: () => import('./pages/sign-in/sign-in.module').then( m => m.SignInPageModule)
  },
  {
    path: 'nifty-script',
    loadChildren: () => import('./pages/nifty-script/nifty-script.module').then( m => m.NiftyScriptPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  // {
  //   path: 'change-password-mobile',
  //   loadChildren: () => import('./pages/change-password/change-password.module').then( m => m.ChangePasswordPageModule)
  // },
  // {
  //   path: 'client-list/:id/:id1/:id2/:id3',
  //   loadChildren: () => import('./pages/client-list/client-list.module').then( m => m.ClientListPageModule)
  // },
  {
    path: 'vol-toppers',
    loadChildren: () => import('./components/vol-toppers/vol-toppers.module').then( m => m.VolToppersPageModule)
  },
  {
    path: 'bulk-block-deals',
    loadChildren: () => import('./components/bulk-block-deals/bulk-block-deals.module').then( m => m.BulkBlockDealsPageModule)
  },
  {
    path: 'aum-equity',
    loadChildren: () => import('./pages/aum-equity/aum-equity.module').then( m => m.AumEquityPageModule)
  },
  {
    path: 'aum-mutual-fund',
    loadChildren: () => import('./pages/aum-mutual-fund/aum-mutual-fund.module').then( m => m.AumMutualFundPageModule)
  },
  {
    path: 'aum-fd',
    loadChildren: () => import('./pages/aum-fd/aum-fd.module').then( m => m.AumFdPageModule)
  },
  {
    path: 'aum-pms',
    loadChildren: () => import('./pages/aum-pms/aum-pms.module').then( m => m.AumPmsPageModule)
  },
  {
    path: 'aum-mlds',
    loadChildren: () => import('./pages/aum-mlds/aum-mlds.module').then( m => m.AumMldsPageModule)
  },
  {
    path: 'sip-live',
    loadChildren: () => import('./pages/sip-live/sip-live.module').then( m => m.SipLivePageModule)
  },
  {
    path: 'sip-new',
    loadChildren: () => import('./pages/sip-new/sip-new.module').then( m => m.SipNewPageModule)
  },
  {
    path: 'sip-bounced',
    loadChildren: () => import('./pages/sip-bounced/sip-bounced.module').then( m => m.SipBouncedPageModule)
  },
  {
    path: 'sip-ceased',
    loadChildren: () => import('./pages/sip-ceased/sip-ceased.module').then( m => m.SipCeasedPageModule)
  },
  {
    path: 'afyp-general-insurance',
    loadChildren: () => import('./pages/afyp-general-insurance/afyp-general-insurance.module').then( m => m.AfypGeneralInsurancePageModule)
  },
  {
    path: 'afyp-life-insurance',
    loadChildren: () => import('./pages/afyp-life-insurance/afyp-life-insurance.module').then( m => m.AfypLifeInsurancePageModule)
  },
  {
    path: 'afyp-health-insurance',
    loadChildren: () => import('./pages/afyp-health-insurance/afyp-health-insurance.module').then( m => m.AfypHealthInsurancePageModule)
  },
  {
    path: 'new-clients',
    loadChildren: () => import('./pages/new-clients/new-clients.module').then( m => m.NewClientsPageModule)
  },
  {
    path: 'mtd-clients',
    loadChildren: () => import('./pages/mtd-clients/mtd-clients.module').then( m => m.MtdClientsPageModule)
  },
  {
    path: 'ytd-clients',
    loadChildren: () => import('./pages/ytd-clients/ytd-clients.module').then( m => m.YtdClientsPageModule)
  },
  {
    path: 'unique-clients',
    loadChildren: () => import('./pages/unique-clients/unique-clients.module').then( m => m.UniqueClientsPageModule)
  },
  {
    path: 'brokerage-mutual-fund',
    loadChildren: () => import('./pages/brokerage-mutual-fund/brokerage-mutual-fund.module').then( m => m.BrokerageMutualFundPageModule)
  },
  {
    path: 'brokerage-equity',
    loadChildren: () => import('./pages/brokerage-equity/brokerage-equity.module').then( m => m.BrokerageEquityPageModule)
  },
  {
    path: 'brokerage-others',
    loadChildren: () => import('./pages/brokerage-others/brokerage-others.module').then( m => m.BrokerageOthersPageModule)
  },
  {
    path: 'fds-booked',
    loadChildren: () => import('./pages/fds-booked/fds-booked.module').then( m => m.FdsBookedPageModule)
  },
  {
    path: 'fds-matured',
    loadChildren: () => import('./pages/fds-matured/fds-matured.module').then( m => m.FdsMaturedPageModule)
  },
  {
    path: 'superstart-shares',
    loadChildren: () => import('./pages/superstart-shares/superstart-shares.module').then( m => m.SuperstartSharesPageModule)
  },
  // {
  //   path: 'price-calculator-mobile',
  //   loadChildren: () => import('./pages/price-calculator/price-calculator.module').then( m => m.PriceCalculatorPageModule)
  // },
  {
    path: '**',
    component: PageNotFoundComponent
  },
  

  

  

 

  

  

  

  

  

  

  // {
  //   path: 'calculators',
  //   loadChildren: () => import('./pages/calculators/calculators.module').then( m => m.CalculatorsPageModule)
  // },

  // {
  //   path: 'settings',
  //   loadChildren: () => import('./pages/settings/settings.module').then( m => m.SettingsPageModule)
  // },

  // {
  //   path: 'mobile-more-option',
  //   loadChildren: () => import('./pages/mobile-more-option/mobile-more-option.module').then( m => m.MobileMoreOptionPageModule)
  // },

  // {
  //   path: 'notification',
  //   loadChildren: () => import('./pages/notification/notification.module').then( m => m.NotificationPageModule)
  // },


  // {
  //   path: 'client-equity-commodity',
  //   loadChildren: () => import('./pages/client-equity-commodity/client-equity-commodity.module').then( m => m.ClientEquityCommodityPageModule)
  // },

  // {
  //   path: 'client-details',
  //   loadChildren: () => import('./pages/client-details/client-details.module').then( m => m.ClientDetailsPageModule)
  // },

  // {
  //   path: 'recently-viewed-client-list',
  //   loadChildren: () => import('./pages/recently-viewed-client-list/recently-viewed-client-list.module').then( m => m.RecentlyViewedClientListPageModule)
  // },

  // {
  //   path: 'detailed-news',
  //   loadChildren: () => import('./pages/detailed-news/detailed-news.module').then( m => m.DetailedNewsPageModule)
  // },

  // {
  //   path: 'invest-bond',
  //   loadChildren: () => import('./pages/invest-bond/invest-bond.module').then( m => m.InvestBondPageModule)
  // }


];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
