import { Injectable } from "@angular/core";
import { URLS } from 'src/config/api.config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
// import { environment } from '../../src/app/environments/environment';

@Injectable()
export class LoginService {

    private chkSub = URLS.checkSubscription;
    private genSubToken = URLS.generateToken;
    private paymentRequest = URLS.paymentRequest;
    private userChk = URLS.userType;
    private super = URLS.superstar;
    private req1 = URLS.request1;
    private req2 = URLS.request2;
    private agree = URLS.isAgree;
    private rmProfile = URLS.rmProfile;
    private mapRM = URLS.mapRM;
    private hold = URLS.holding;
    private subMap = URLS.subBrokerMap;
    private cookieSwaraj = URLS.swarajCookie;
    private cookieBackofc = URLS.backofficeCookie;
    private cookieIsAgree = URLS.isAgreeCookie;
    private cookieHolding = URLS.holdingCookie;
    public headersParams = environment['headersParams'];
    private cookieKEY = environment['cookieKEY'];
 
   
    constructor(private httpClient: HttpClient) {
    }

    public checkSubs(cookieValue, id): Observable<{}> {
        const params = {
            head: {
                RequestCode: "MFRQLO01",
                Key: this.chkSub.key,
                AppVer: "1.0.4.0",
                AppName: "AAA",
                OsName: "Android"
            },
            body: {
                Loginid: id
            }
        }
        let obj = {};
        if (cookieValue) {
            obj = {
                'token': cookieValue
            }
        }
        return this.httpClient.post(this.chkSub.url, params, { headers: new HttpHeaders(Object.assign(obj,this.cookieKEY)) });
    }

    public genToken(cookieValue, dataObj): Observable<{}> {
        const passObj = {
            Authorization : 'Basic ' + btoa('SaMhAKLA4lJu6TJhvbdId8KiNmQfOxEmr4B86xEeEO0=:SaMhAKLA4lJu6TJhvbdIdxhqdwzxBiT2TofUgd3NtpI='),
            ReqSource: 'APP'
        }
        let obj = {};
        if (cookieValue) {
            obj = {
                'token': cookieValue
            }
        }
        return this.httpClient.post(this.genSubToken.url, dataObj, { headers: new HttpHeaders(Object.assign(passObj, obj, this.cookieKEY)) });
    }

    public payTMPaymentRequest(cookieValue, dataObj): Observable<{}> {
        const passObj = {
            Authorization : 'Basic ' + btoa('SaMhAKLA4lJu6TJhvbdId8KiNmQfOxEmr4B86xEeEO0=:SaMhAKLA4lJu6TJhvbdIdxhqdwzxBiT2TofUgd3NtpI=')
        }
        let obj = {};
        if (cookieValue) {
            obj = cookieValue
        }
        return this.httpClient.post(this.paymentRequest.url, dataObj, { headers: new HttpHeaders(Object.assign(passObj, obj, this.cookieKEY)) });
    }

    public getUserType(id): Observable<{}> {
        const params = {
            head: {
                RequestCode: "CVUserType01",
                Key: this.userChk.key,
                AppVer: "1.0.22.0",
                AppName: "AAA",
                OsName: "Android"
            },
            body: {
                UserCode: id
            }
        }
        return this.httpClient.post(this.userChk.url, params, { headers: new HttpHeaders(this.cookieKEY) });
    }

    public requestOne(userID?,dataParams?): Observable<{}> {
        const params = {
            "head": {
                "requestCode": "IIFLMarRQLR02",
                "key": this.req1.key,
                "appVer":"1.0.22.0",
                "appName":"AAA",
                "osName":"Android",
                "LoginId": userID
            }
        }
        params['body'] = dataParams
        
        return this.httpClient.post(this.req1.url, params, { observe: 'response', headers: new HttpHeaders(this.cookieKEY)});
        // return this.httpClient.post(this.req1, params);
    }

    public requestTwo(dataParams?): Observable<{}> {
        const params = {
            "head": {
                "requestCode": "CVUpdateLead01",
                "key": this.req2.key,
                "appVer":"1.0.22.0",
                "appName":"AAA",
                "osName":"Android"
            }
        }
        params['body'] = dataParams
       // return this.httpClient.post(this.req2.url, params, { withCredentials: true});
       return this.httpClient.post(this.req2.url, params, {headers: new HttpHeaders(this.cookieKEY)});
    }

    public getRMProfile(dataParams?, cookieValue?): Observable<{}> {
        const params = {
            "head": {
                "RequestCode": "CVUpdateLead01",
                "Key": this.rmProfile.key,
                "AppName": "AAA",
                "AppVer": "1.0.22.0",
                "OsName": "Android"
            }
        }
        let obj = {};
        if (cookieValue) {
            obj = {
                'token': cookieValue
            }
        }
        params['body'] = dataParams
        return this.httpClient.post(this.rmProfile.url, params, {headers: new HttpHeaders(Object.assign(obj,this.cookieKEY))});
    }

    public getRMMapping(dataParams, id, cookievalue): Observable<{}> {
        const params = {
            "head": {
                "requestCode": "IIFLMarManagermappingreq",
                "key": this.mapRM.key,
                "appVer": "1.0.22.0",
                "appName": "IIFLMarkets",
                "osName": "Android",
                "loginId": localStorage.getItem('userID'),
                "LoginId": localStorage.getItem('userID')
            }
        }
        let obj = {};
        if (cookievalue) {
            obj = {
                'token': cookievalue
            }
        }
        params['body'] = dataParams
        return this.httpClient.post(this.mapRM.url, params, {headers: new HttpHeaders(Object.assign(obj,this.cookieKEY))});
    }

    public getAgree(dataParams?, cookieValue?): Observable<{}> {
        // const bodyParam = environment['bodyParams'];
        // let params = {
            const head = {
                "RequestCode": "CVUserType01",
                "Key": this.agree.key,
                "AppVer":"1.0.22.0",
                "AppName":"AAA",
                "OsName":"Android"
            }
        // };
        let params = {};

        params['head'] = head;
        params['body'] = dataParams;
        let obj = {};
        if (cookieValue) {
            obj = {
                'token': cookieValue
            }
        }
        
       // return this.httpClient.post(this.agree.url, params, { withCredentials: true });
       return this.httpClient.post(this.agree.url, params, {headers: new HttpHeaders(Object.assign(obj,this.cookieKEY))});
    }

    public getSubBrokerLogin(userID?,dataParams?): Observable<{}> {
        const params = {
            "head": {
                "requestCode": "IIFLMarRQLR02",
                "key": this.req1.key,
                "appVer":"1.0.22.0",
                "appName":"AAA",
                "osName":"Android",
                "LoginId": userID
            }
        }
        params['body'] = dataParams
        
        return this.httpClient.post(this.req1.url, params, { observe: 'response', headers: new HttpHeaders(this.cookieKEY)});
    }

    public getSubBrokerMap(dataParams?, cookieValue?): Observable<{}> {
        // const bodyParam = environment['bodyParams'];
        let params = {
            "head": {
                "RequestCode": "CVUpdateLead01",
                "Key": this.subMap.key,
                "AppName": "AAA",
                "AppVer": "1.0.18.0",
                "OsName": "Android"
            }
        };
        let obj = {};
        console.log(cookieValue);
        params['body'] = dataParams;
        
        if (cookieValue) {
            obj = {
                'token': cookieValue
            }
        }
        
        return this.httpClient.post(this.subMap.url, params, {headers: new HttpHeaders(Object.assign(obj,this.cookieKEY))} );
    }

    public getHolding(dataParams?): Observable<{}> {
        // const bodyParam = environment['bodyParams'];
        let params = {
            "head":{"requestCode":"IIFLMarRQMHOC","key":this.hold.key,"appVer":"1.0.22.0","appName":"AAA","osName":"Android","LoginId":"ckv000rm"},"body":{}
        };
        
        return this.httpClient.post(this.hold.url, params, {headers: new HttpHeaders(this.cookieKEY)});
    }

    public getsuper(dataParams?): Observable<{}> {
        // const bodyParam = environment['bodyParams'];
        let params = {};

        params = dataParams;
        
        return this.httpClient.post(this.super, params);
    }

    public getFirstCookie(dataParams?) : Observable<{}> {
        // const bodyParam = environment['bodyParams'];
        let params = {};

        params = dataParams;
        
        return this.httpClient.post(this.cookieSwaraj, params, { headers: new HttpHeaders(this.cookieKEY) });
    }

    public getSecondCookie(dataParams?) : Observable<{}> {
        // const bodyParam = environment['bodyParams'];
        let params = {};

        params = dataParams;
        
        return this.httpClient.post(this.cookieBackofc, params, { headers: new HttpHeaders(this.cookieKEY) });
    }

    public getThirdCookie(dataParams?) : Observable<{}> {
        // const bodyParam = environment['bodyParams'];
        let params = {};

        params = dataParams;
        
        return this.httpClient.post(this.cookieIsAgree, params, { headers: new HttpHeaders(this.cookieKEY) });
    }

    public getFourthCookie(dataParams?) : Observable<{}> {
        // const bodyParam = environment['bodyParams'];
        let params = {};

        params = dataParams;
        
        return this.httpClient.post(this.cookieHolding, params, { headers: new HttpHeaders(this.cookieKEY) });
    }
}