import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, from } from 'rxjs';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor() { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        
        const headers = {};
            headers['Cache-Control'] = 'no-cache';
            headers['Pragma'] = 'no-cache';
            headers['language_code'] = 'en';
            headers['Content-Type'] = 'application/json';
            headers['clientID'] = localStorage.getItem('userID') ? localStorage.getItem('userID') : 'NA';
            req = req.clone({
                setHeaders: headers,
                // withCredentials: true
            });

            // Handle the request
            return next.handle(req);
    }

}
