import { Component, HostListener, NgZone } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Platform, NavController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { ScreensizeService } from './helpers/screensize.service';
import { Router } from '@angular/router';
import { Storage } from '@ionic/storage';
import { ModalController } from '@ionic/angular';
import { SessionExpiredComponent } from 'src/app/components/session-expired/session-expired.component'

@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.scss']
})
export class AppComponent {

	public getFeed;
	newValue: any
	oldValue: any;
	seconds: any;
	constructor(
		private platform: Platform,
		private splashScreen: SplashScreen,
		private statusBar: StatusBar,
		private storage: Storage,
		private screensize: ScreensizeService,
		private navCtrl: NavController,
		private route: Router,
		private titleService: Title,
		private ngZone: NgZone,
		public modalController: ModalController
	) {
		clearTimeout(this.getFeed);
		this.initializeApp();
		// console.log(document.location.pathname);
		// // store location path last navigation
		// localStorage.setItem('path', document.location.pathname);
	}

	initializeApp() {
		this.platform.ready().then(() => {
			this.statusBar.styleDefault();
			this.statusBar.backgroundColorByHexString('#524091');
			this.statusBar.styleLightContent();
			this.statusBar.overlaysWebView(false);
			this.splashScreen.hide();

			// const path = window.location.pathname;
			// if (path === '/') this.navCtrl.navigateRoot(['/markets'])
			// else this.navCtrl.navigateRoot([path]);
			this.screensize.onResize(this.platform.width());
			// window.console.log = function() {};

			this.ngZone.run( () => {
				this.storage.get('token').then((response) => {
					if (response) {
						console.log(response, 'succss');
	
						const path = window.location.pathname;
						// if (path === '/sign-in') {
						//   this.route.navigateByUrl(path);
						// } else {
							console.log(path, 'path');
							
						if (path === '/login') {
							console.log('login');
							
							this.route.navigate(['/markets']);
						} else {
							console.log('----path----');
							this.route.navigate([path]);
						}
						// }
					} else {
						const path = window.location.pathname;
						// if (path === '/sign-in') {
						//   this.route.navigateByUrl(path);
						// } else {
						// }
						console.log('path with no response');
						
						this.route.navigate(['/login']);
						console.log('no response');
	
					}
	
					this.checkTime();
				})
			})

			// this.navCtrl.navigateRoot(['/login'])
			// if (path === '/') 
			// else this.navCtrl.navigateRoot([path]);
			this.screensize.onResize(this.platform.width());
		});
	}

	@HostListener('window:resize', ['$event'])
	private onResize(event) {
		this.screensize.onResize(event.target.innerWidth);
	}

	public checkTime() {
		this.storage.get('loginTime').then(value => {
			this.getFeed = setTimeout(() => {
				this.checkTime();
				if (value) {
					// const HOUR = 1000 * 60 * 60;
					this.newValue = new Date();
					this.oldValue = new Date(value);

					var diff = (this.newValue.getTime() - this.oldValue.getTime());
					var res = Math.abs(this.newValue - this.oldValue) / 1000;

					var days = Math.floor(res / 86400);

					// get hours        
					var hours = Math.floor(res / 3600) % 24;

					// get minutes
					var minutes = Math.floor(res / 60) % 60;
					// get seconds
					this.seconds = res % 60;

					// console.log("Time:",hours +":"+minutes);
					// console.log('minutes',minutes);

					if ((hours.toString() == '8' || hours.toString() == '08' || hours.toString() > '8') && (minutes.toString() == "0" || minutes.toString() > "0") && (parseInt(this.seconds) == 0)) {
						clearTimeout(this.getFeed);
						this.storage.get('userType').then( user => {
							this.sessionExpired(user);
						})
					}

					// console.log(Date.parse(oldValue.toString()),Date.parse(newValue.toString()));

					// console.log(Date.parse(oldValue.toString()) > Date.parse(newValue.toString()));

				}
			}, 1000)
		})
	}

	async sessionExpired(user) {
		this.modalController.dismiss();
		const modal = await this.modalController.create({
			component: SessionExpiredComponent,
			backdropDismiss: false,
			cssClass: 'forgot-password-popup-mobile session-expired',
			componentProps: {
				userType: user
			}
		});
		// this.storage.clear();

		/* modal.onDidDismiss().then((data) => {
			if (data['data']) {
				console.log(data['data']);

			}
		}) */

		return await modal.present();
	}

	// public setTitle( newTitle: string) {
	//   this.titleService.setTitle( newTitle );
	// }
}
